import React from "react"
import { Dropdown, Menu } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import ScrollAnimation from "react-animate-on-scroll"

import imgCompanyLogo from "../media/Logo-SecuriteAssociates-30x30.png"
import imgClientsNouvelle from "../media/Image-Clients-Nouvelle-500x500.png"
import imgKnightFrank from "../media/Image-Clients-KnightFrank-500x500.png"
import imgAlexResidences from "../media/Image-Clients-AlexResidences-500x500.png"
import imgClientsAbsolut from "../media/Image-Clients-Absolut-500x500.png"
import imgJuEngHome from "../media/Image-Clients-JuEngHome-500x500.png"
import imgCrawfurdHospital from "../media/Image-Clients-CrawfurdHospital-500x500.png"
import imgSecurityConsultancy from "../media/Image-SecurityConsultancy3.png"
import imgSecurityTechnology from "../media/Image-SecurityTechnology3.png"
import imgSecurityManpower from "../media/Image-SecurityManpower3.png"
import imgFacebook from "../media/Icon-Facebook-30x30.png"
import imgBanner from "../media/Image-Banner2.png"

const Index = () => {
    const nav = [
        { title: "Home", link: "#company" },
        { title: "About", link: "#about" },
        { title: "Services", link: "#services" },
        { title: "Clients", link: "#clients" },
        { title: "Contact", link: "#contact" },
    ]

    const renderNavItems = (array) => {
        const navItems = array.map((item) => {
            return (
                <a href={item.link} className="nav-item" key={item.title}>
                    {item.title}
                </a>
            )
        })
        return navItems
    }

    const renderNav = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderNavItems(array)
            )
        )
    }

    const renderDropdownNavItems = (array) => {
        const menuItems = array.map((item) => {
            return (
                <Menu.Item key={item.title} >
                    <a href={item.link}>{item.title}</a>
                </Menu.Item>
            )
        })
        return menuItems
    }

    const renderDropdownMenuItems = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderDropdownNavItems(array)
            )
        )
    }

    const dropdownMenuContent = (array) => {
        return (
            <Menu style={{ maxWidth: "250px" }}>
                {renderDropdownMenuItems(array)}
            </Menu>
        )
    }
    return (
        <div className="index">
            <div className="container">
                <div className="header">
                    <a href="#company" className="container-logo">
                        <div className="logo">
                            <img src={imgCompanyLogo} alt="Securite Logo" />
                        </div>
                        <div className="text">
                            <span style={{ color: "#1a3590" }}>Securite</span> <span style={{ color: "#52a646" }}>Associates</span>
                        </div>
                    </a>
                    <div className="row nav above-sm">
                        {renderNav(nav)}
                    </div>
                    <Dropdown overlay={dropdownMenuContent(nav)} trigger={["click"]}>
                        <MenuOutlined className="below-sm" />
                    </Dropdown>
                </div>
                <div className="company" id="company">
                    <div className="container-bannerimg">
                        <img src={imgBanner} alt="Securite Banner" />
                    </div>
                </div>
                <div className="about" id="about">
                    <div className="title">
                        About
                    </div>
                    <div className="column">
                        <div className="item">
                            <div className="item-title">
                                Exceptional Service
                            </div>
                            <div className="item-subtitle">
                                Since 2006, Securite Associates Pte Ltd has provided quality services to clients by providing them with the professional care they deserve. Get in touch today to learn more about our Security Management & Consultancy services .
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-title">
                                Professionalism. Excellence. Timeliness.
                            </div>
                            <div className="item-subtitle">
                                Over the years, Securite Associates has substantially invested in growing and maintaining our Quality Assured Management Practices, and we use these learned efficiencies to amplify our clients’ success. Our security solutions can be tailored to your organisation’s needs, and our approach to managing your security is as flexible as your requirements dictate. We have one key goal in mind: to provide you with an all-encompassing security solution that allows your organisation to operate efficiently, effectively and safely.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services" id="services">
                    <div className="title">
                        Our Services
                    </div>
                    <div className="row">
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="image">
                                    <img src={imgSecurityConsultancy} alt="Security Consultancy" />
                                </div>
                                <div className="item-title">
                                    Security Consultancy
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={200}>
                                <div className="image">
                                    <img src={imgSecurityTechnology} alt="Security Technology" />
                                </div>
                                <div className="item-title">
                                    Security Technology
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={400}>
                                <div className="image">
                                    <img src={imgSecurityManpower} alt="Security Manpower" />
                                </div>
                                <div className="item-title">
                                    Security Manpower
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="clients" id="clients">
                    <div className="title">
                        Our Clients
                    </div>
                    <div className="container-text">
                        <p>In these challenging times, when manpower is scarce and regulatory forces shape security agencies, the agency must have the resources and breadth of experience to work with you. More importantly, the agency must have a listening ear and the persistence to keep engaging you.</p>
                        <p>Securite Associates has built a reputation for providing professional and consistent service to our clients. Our client roster is quite diverse. Residential, schools, commercial, shopping malls, protected areas, publicly traded companies, gaming industries, and so on are examples.</p>
                        <p>The seamless integration of man, machine, and methods help achieved security in us.</p>
                    </div>
                    <div className="row">
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={0}>
                                <div className="image">
                                    <img src={imgClientsNouvelle} alt="Nouvelle Property Management Solutions" />
                                </div>
                                <div className="item-title">
                                    Nouvelle Property Management Solutions
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={200}>
                                <div className="image">
                                    <img src={imgKnightFrank} alt="Knight Frank" />
                                </div>
                                <div className="item-title">
                                    Knight Frank
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={400}>
                                <div className="image">
                                    <img src={imgAlexResidences} alt="Alex Residences" />
                                </div>
                                <div className="item-title">
                                    Alex Residences
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={600}>
                                <div className="image">
                                    <img src={imgClientsAbsolut} alt="Absolut Properties" />
                                </div>
                                <div className="item-title">
                                    Absolut Properties
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item display-none">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={0}>
                                <div className="image">
                                    <img src={imgJuEngHome} alt="Ju Eng Home For Senior Citizens" />
                                </div>
                                <div className="item-title">
                                    Ju Eng Home For Senior Citizens
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={0}>
                                <div className="image">
                                    <img src={imgCrawfurdHospital} alt="Crawfurd Hospital" />
                                </div>
                                <div className="item-title">
                                    Crawfurd Hospital
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="contact" id="contact">
                    <div className="title">
                        Contact
                    </div>
                    <div className="row">
                        <div className="item">
                            <div className="item-title">
                                Phone
                            </div>
                            <div className="item-subtitle">
                                <span>
                                    For call enquires.
                                </span>
                                <br />
                                <span className="highlight">
                                    +65 6285 1328
                                </span>
                            </div>
                            <br /><br />
                            <div className="item-subtitle">
                                <span>
                                    For Whatsapp enquires.
                                </span>
                                <br />
                                <a href="https://wa.me/93889698" target="_blank" rel="noreferrer noopener">
                                    +65 9388 9698
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-title">
                                Email / Feedback
                            </div>
                            <div className="item-subtitle">
                                <span>
                                    Email<br />
                                    We'll respond within 3 to 5 working days of your request.
                                </span>
                                <br />
                                <span className="highlight">
                                    admin@securite.com.sg
                                </span>
                            </div>
                            <br /><br />
                            <div className="item-subtitle">
                                <span>
                                    Provide Feedback
                                </span>
                                <br />
                                <span className="highlight">
                                    <a href="https://www.securite.inimicode.com/feedback" target="_blank" rel="noreferrer noopener">
                                        Feedback Form
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-title">
                                Office
                            </div>
                            <div className="item-subtitle">
                                <span>
                                    Opening Hours:
                                </span>
                                <br />
                                Mon to Fri - 0900 to 1700<br />
                                Sat, Sun and PH - Closed<br />
                                <i style={{ fontSize: "14px" }}>Note: Interviews by appointment only.</i>
                                <br /><br />
                                <span className="highlight">
                                    Kensington Park Condo<br />
                                    8 Kensington Park Drive #01-02 Singapore, Singapore 557323
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="copyright">
                        Copyright &copy; 2022 Securite Associates. All rights reserved.
                    </div>
                    <div className="social">
                        <div className="text">
                            Follow our social media!
                        </div>
                        <div className="container-icon">
                            <a className="item" href="https://www.facebook.com/SecuriteAssociate/" target="_blank" rel="noreferrer noopener">
                                <img src={imgFacebook} alt="facebook"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index